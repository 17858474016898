.section--contact {
    margin: 50px 0;
    padding: 110px 20px 0;
}
.section--contact .contact-title {
    max-width: 1600px;
    margin: 0 auto;
    font-size: 60px;
    color: #f1f2f3;
}
.section--contact .inner {
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
}
.section--contact .wrapper-inner {
    flex: 85% 1;
}
.section--contact .contact-subtitle {
    font-size: 25px;
    color: #c1c2c3;
    margin-top: 50px;
    font-weight: 300;
}
.section--contact .contact-introduce {
    margin-top: 50px;
    font-size: 25px;
    font-weight: 500;
    color: #c1c2c3;
}
.section--contact .social-links {
    margin-top: 50px;
}

@media screen and (max-width: 1380px) {
    .section--contact {
        padding: 90px 20px 0;
    }
    .section--contact .contact-title {
        font-size: 45px;
    }
    .section--contact .contact-subtitle {
        font-size: 20px;
    }
}

@media screen and (max-width: 768px) {
    .section--contact .contact-title {
        font-size: 32px;
        font-weight: 500;
        text-align: center;
    }
    .section--contact .inner {
        display: block;
        margin-bottom: 40px;
    }
    .section--contact .profile-img {
        margin-top: 30px;
        text-align: center;
    }
    .section--contact .wrapper-inner {
        text-align: center;
    }
}
