.main {
    max-width: 1600px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 110px 20px 0;
}
.main h1 {
    font-size: 60px;
    font-weight: 400;
    color: #f1f2f3;
}
.main .repo-cards {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 32%);
    column-gap: 2%;
}
.main a {
    display: block;
    width: max-content;
    padding: 15px 30px;
    margin: 15px auto 0;
    border: 2px solid #c1c2c3;
    background-color: #121314;
    text-align: center;
    font-size: 20px;
    color: #c1c2c3;
    text-decoration: none;
    border-radius: 8px;
}
.main a:hover {
    color: #121314;
    background-color: #c1c2c3;
    transition: 0.3s ease-out;
}

@media screen and (max-width: 1380px) {
    .main {
        padding: 90px 20px 0;
    }
    .main h1 {
        font-size: 45px;
    }
    .main .repo-cards {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}

@media screen and (max-width: 768px) {
    .main h1 {
        font-size: 32px;
        text-align: center;
        font-weight: 500;
    }
    .main a {
        font-size: 16px;
    }
    .main .repo-cards {
        grid-template-columns: repeat(auto-fit, 100%);
    }
}
