.social-media-links {
    margin-top: 30px;
    font-size: 22px;
}
.social-media-links .icon-button i {
    padding: 10px;
    display: inline-block;
    color: #ffffff;
    box-sizing: border-box;
    border-radius: 50%;
    margin: 5px 5px;
    transition: 0.3s ease-out;
}
.github i {
    background-color: #0073b1;
}
.linkedin i {
    background-color: #0073b1;
}
.email i {
    background-color: #ea4335;
}
.facebook i {
    background-color: #008000;
}
.twitter i {
    background-color: #2aa9e0;
}
.instagram i {
    background-color: #a056a1;
}
.medium i {
    background-color: #000000;
}
.stackoverflow i {
    background-color: #f48024;
}
.icon-button > i:hover {
    background-color: #000000;
}
