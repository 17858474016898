.section--achievement {
    margin-top: 50px;
    padding: 110px 20px 0;
}
.section--achievement .inner {
    max-width: 1600px;
    margin: 0 auto;
}
.section--achievement .achieve-title {
    font-size: 60px;
    color: #f1f2f3;
}
.section--achievement .achieve-subtitle {
    margin-top: 50px;
    font-size: 25px;
    color: #c1c2c3;
    font-weight: 300;
}
.section--achievement .achieve-content {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 32%);
    column-gap: 2%;
}

@media screen and (max-width: 1380px) {
    .section--achievement {
        padding: 90px 20px 0;
    }
    .section--achievement .achieve-title {
        font-size: 45px;
    }
    .section--achievement .achieve-subtitle {
        font-size: 20px;
    }
    .section--achievement .achieve-content {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}

@media screen and (max-width: 768px) {
    .section--achievement .achieve-title {
        text-align: center;
        font-size: 32px;
        font-weight: 500;
    }
    .section--achievement .achieve-subtitle {
        text-align: center;
    }
    .section--achievement .achieve-content {
        grid-template-columns: repeat(auto-fit, 100%);
    }
}
