.section--skill {
    padding: 110px 20px 0;
    position: relative;
    margin-top: 50px;
}
.section--skill .inner {
    max-width: 1600px;
    display: flex;
    margin: 0 auto;
}
.section--skill .wrapper {
    margin-left: 10px;
    flex: 60%;
}
.section--skill .skill-title {
    font-size: 60px;
    font-weight: 400;
    color: #f1f2f3;
}
.section--skill .skill-subtitle {
    margin-top: 50px;
    font-size: 25px;
    font-weight: 300;
    color: #c1c2c3;
}
.section--skill .skill-cards {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 20%);
    row-gap: 20px;
}
.section--skill .skill-desc {
    margin-top: 50px;
    font-size: 22px;
    color: #c1c2c3;
}
.section--skill .skill-desc p {
    margin-top: 30px;
    font-weight: 300;
}
.section--skill .skill-image {
    flex: 40%;
    font-size: 300px;
    color: #f1f1f1;
    align-self: center;
    text-align: center;
}

@media screen and (max-width: 1380px) {
    .section--skill {
        padding: 90px 20px 0;
    }
    .section--skill .skill-title {
        font-size: 45px;
    }
    .section--skill .skill-subtitle, .section--skill .skill-desc {
        font-size: 20px;
    }
    .section--skill .skill-cards {
        grid-template-columns: repeat(auto-fit, 25%);
    }
    .section--skill .skill-image {
        font-size: 200px;
    }
}

@media screen and (max-width: 980px) {
    .section--skill .skill-cards {
        grid-template-columns: repeat(auto-fit, 32%);
    }
}

@media screen and (max-width: 768px) {
    .section--skill .inner {
        display: block;
    }
    .section--skill .skill-title {
        font-size: 32px;
        text-align: center;
        font-weight: 500;
    }
    .section--skill .skill-subtitle {
        text-align: center;
    }
    .section--skill .skill-image {
        display: none;
    }
    .section--skill .skill-cards {
        grid-template-columns: repeat(auto-fit, 50%);
    }
}
