.section--exp {
    margin-top: 50px;
    padding: 110px 20px 0;
}
.section--exp .inner {
    max-width: 1600px;
    margin: 0 auto;
}
.section--exp .exp-title {
    font-size: 60px;
    color: #f1f2f3;
}
.section--exp .exp-cards {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2%;
}

@media screen and (max-width: 1380px) {
    .section--exp {
        padding: 90px 20px 0;
    }
    .section--exp .exp-title {
        font-size: 45px;
    }
    .section--exp .exp-cards {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}

@media screen and (max-width: 768px) {
    .section--exp .exp-title {
        font-size: 32px;
        text-align: center;
        font-weight: 500;
    }
    .section--exp .exp-cards {
        grid-template-columns: repeat(auto-fit, 100%);
    }
}
