.blog-card {
    background-color: #212223;
    border-radius: 10px;
    box-shadow: 13px 13px 6px rgba(0,0,0,0.2);
    padding: 30px;
    margin-bottom: 30px;
    text-decoration: none;
}
.blog-card:hover {
    transition: 0.3s ease-out;
    box-shadow: 0 4px 8px rgba(38,38,38,0.2);
}
.blog-card .blog-card-title {
    font-size: 25px;
    color: #f1f2f3;
    font-weight: 500;
}
.blog-card .blog-card-desc {
    line-height: 1.4;
    margin-top: 20px;
    font-size: 20px;
    color: #c1c2c3;
    font-weight: 300;
    -webkit-box-orient: vertical;
    display: -webkit-inline-box;
    overflow:;
    -webkit-line-clamp: 23;
}

@media screen and (max-width: 1380px) {
    .blog-card .blog-card-title {
        font-size: 20px;
    }
    .blog-card .blog-card-desc {
        font-size: 16px;
    }
}
