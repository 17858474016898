.skill-card-main {
    margin: 0px 10px;
    padding: 20px 30px;
    height: auto;
    color: #c1c2c3;
    text-align: center;
    border-radius: 16px;
    box-shadow: 13px 13px 6px rgba(0, 0, 0, 0.2);
    background-color: #212223;
    height: 140px;
    transition: 0.3s ease-out;
}
.skill-card-main:hover {
    box-shadow: 0 4px 8px rgba(38, 38, 38, 0.2);
}
.skill-card-main .skill-icon {
    font-size: 80px;
}
.skill-card-main .skill-text {
    margin-top: 30px;
    font-size: 22px;
}

@media screen and (max-width: 1380px) {
    .skill-card-main .skill-icon {
        font-size: 35px;
    }
    .skill-card-main .skill-text {
        font-size: 20px;
    }
}
